import React, { Component } from "react";
import '../Models.css'
import imgInfoCar from "../../../../assets/Маторики/info car img.png"
import complictionCar from "../../../../assets/Маторики/complection car img.png"

class Characteristic extends Component {
    state = {

    };
    render() {
        const { infoDataBase } = this.props;
        if (infoDataBase) {
            let equipment = infoDataBase.definitiv.slice(0, 6).map(item => {
                return (<h4 key={item.text}>{item.text}</h4>)
            });
            return (
                <>
                    <div className={"border-infoCar m-top-150"}>
                        <div> <h1 className={"text-models-body "}>ОБЗОР</h1></div>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}><p className={"info-characteristic-car"}>Выберите машину и информация по ниже будет изменятся</p></div>
                    </div>
                    <div className={"border-infoCar-text"}>
                        <div>
                            <h1>Двигатель/Трансмиссия</h1>
                            <h4>топливо________________________{infoDataBase.fuel}</h4>
                            <h4>объём_____________________________{ infoDataBase.volume.toUpperCase().includes('Л') ? infoDataBase.volume: infoDataBase.volume + "л"}</h4>
                            <h4>максимальная скор._________{infoDataBase.max_speed.toLowerCase().includes('км/ч') ? infoDataBase.max_speed: infoDataBase.maxSpeed + "км/ч"}</h4>
                            <h4>мощность двигателя________{infoDataBase.power.toLowerCase().includes('.об.мин') ? infoDataBase.power: infoDataBase.power + ".об.мин"}</h4>
                            <h4>тип привода___________________{infoDataBase.drive_type}</h4>
                            <h4>Коробка передач______________{infoDataBase.transmission}</h4>
                            <h4>размер шин____________________{infoDataBase.wheels}</h4>
                        </div>
                        <div>
                            <img className={"img"} src={imgInfoCar} alt="img" />
                        </div>
                    </div>
                    <div className={"border-infoCar-text "}>
                        <div className={"img1-infoCar"}>
                            <img className={"img"} src={complictionCar} alt="img" />
                        </div>
                        <div className={"textInfocar"}>
                            <h1>ОСНОВНЫЕ ОСОБЕННОСТИ/КОМПЛЕКТАЦИИ</h1>
                            {equipment}
                        </div>
                        <div className={"img2-infoCar"}>
                            <img className={"img"} src={complictionCar} alt="img" />
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <hr />
                </>
            )
        }else{
            <h1>Загрузка...</h1>
        }
    }
}

export default Characteristic;
