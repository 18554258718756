import { Component } from "react";
import "./Active.css";
import Form from "./Form/form";
import logo from "../../../assets/Маторики/Logo.png"
import noCar from "../../../assets/Маторики/No car.png"
import imgConfig from "../../../assets/Маторики/Конфигуратция.png"
import logotip from "../../../assets/Маторики/vehica-logo-dark-retina-2.png"
import pdfImg from "../../../assets/Маторики/pdf img.png"
import Modification from "./Modification/modifig"
import { getCar, getCars } from "../../../redux/reducers/app";
import { connect } from "react-redux";
import withRouter from "../../../helpers/params";
import { Link } from "react-router-dom";


class Activcar extends Component {
    state = {
        database: null,
        active: {},
        modification: false,
        color: 0,
        url: window.location.href,
    };

    componentDidMount() {
        this.loadData();
    }
    componentDidUpdate(prevProps) {
        if (
            (this.props.cars.length === 0 || Object.keys(this.props.car).length === 0) &&
            (prevProps.cars.length !== this.props.cars.length || prevProps.car.id !== this.props.car.id)
        ) {
            this.loadData();
        }
        if (prevProps.cars.length !== this.props.cars.length) {
            this.setState({
                database: this.props.cars,
            });
        }
        if (prevProps.car && this.props.car && prevProps.car.id !== this.props.car.id && this.props.car.colors && this.props.car.colors.length > 0) {
            this.setState({
                active: this.props.car,
                color: this.props.car.colors !== []? this.props.car.colors[0].id : 0,
            });
        }
    }
    reloadPage(urls) {
        window.location.href = urls;
    }
    loadData() {
        const { dispatch } = this.props;
        const { id } = this.props.params;
        dispatch(getCars());
        if (id !== 0) {
            dispatch(getCar(id));
        }
    }
    refreshPage() {
        window.location.reload();
    }
    render() {
        const { database, active, color,  modification} = this.state;
        return (
            <>
                {/* Модификатция*/}
                {modification ? <Modification exit={() => { this.setState({ modification: false }) }} database={database} activ={active} /> : null}
                {/*//// */}
                <div className={"blok-activCar"} style={{ backgroundImage: "linear-gradient(#4B4E61,  #AFB5E2)" }} id={"machine_overview"}>
                    <div className={"activ-text-car"}>
                        <h1 className={"name-car-activ"}>{active === {} ? null : active.title}</h1>
                        <p className={"pot-text-carName"}>{active === {} ? null : active.description}</p>
                        <a  href={"#peculiaritiesForm"} style={{color:'#fefefe'}}>
                        <div className={"activ-booton-info-jetour"}>
                                тест-драйв  &#8594;
                            </div>
                        </a>
                    </div>
                    <div className={"car-img-activ"}>
                        {!active && !active.colors && active.colors.length === 0
                            ?null:
                            <img src={active && active.colors && active.colors[color] ? active.colors[color].car_image : noCar}
                                 alt={active === {}? null : active.title}/>
                        }
                    </div>
                    <div className={"color-activCar"}>
                        {!active === null && !active.colors && active.colors.length === 0  ? null :
                        active.colors &&active.colors.length > 0?
                        active.colors.map((e, index) => (
                            <div key={e.id} style={{ backgroundColor: e.color , cursor:'pointer'}} onClick={() => { this.setState({ color: index }) }} />
                        ) ):'Загрузка'
                        } 
                    </div>
                    <div className={"text-jetour-activ"}>
                        <img src={logo} />
                    </div>
                    <div className={"activ-info-car"}>
                        <div style={{ backgroundColor: "#2F313E" }}>
                            <div>
                                <h1><span className={"big-text"}>{active !== null ? active.power : "147"}</span></h1>
                                <p className={"pot-text"}>Мощность двигателя</p>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#24242E" }}>
                            <div>
                                <h1><span className={"big-text"}>{active !== null ? active.consumption : "147"}</span></h1>
                                <p className={"pot-text"}>Расход на 100km</p>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#2F313E" }}>
                            <div>
                                <h1><span className={"big-text"}>{active !== null ? active.volume : "1.5"}</span>л</h1>
                                <p className={"pot-text"}>Обём двигателя</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"section1-activCar"}>
                    <div className={"activ-img1"}>
                        <img className={"img-section1"} src={active ? active.design_image : "https://www.livecars.ru/l/news/2017/11/03/nissan-gt-r-2018/1.jpg?1509725147"} />
                    </div>
                    <div className={"text"} style={{ backgroundColor: "#62647D" }}>
                        <span>
                            <h2>Внешний дизайн</h2>
                            <p style={{wordBreak:'break-all', wordWrap:'break-word'}}>{active ? active.design : "Jetour Dashing 2023 часто сравнивают с Lamborghini Urus, что объясняется похожей внешностью этих машин. Оба кроссовера имеют характерный «хищный» передок, но у «китайца» совсем иная – безрамочная радиаторная решетка, а также элегантная двухэтажная оптика."}</p>
                        </span>
                    </div>
                    <div className={"activ-img2"}>
                        <img className={"img-section1"} src={active ? active.design_image : "https://www.livecars.ru/l/news/2017/11/03/nissan-gt-r-2018/1.jpg?1509725147"} />
                    </div>
                </div>
                <div className={"section1-activCar"}>
                    <div>
                        <img className={"img-section1"} src={active ? active.interior_image : "https://www.livecars.ru/l/news/2017/11/03/nissan-gt-r-2018/1.jpg?1509725147"} />
                    </div>
                    <div className={"text"} style={{ backgroundColor: "#2F313E" }}>
                        <span>
                            <h2>Салон</h2>
                            <p style={{wordBreak:'break-all', wordWrap:'break-word'}}>{active ? active.interior : ""}</p>
                        </span>
                    </div>

                </div>
                <div className={"section1-activCar"}>
                    <div className={"activ-img1"}>
                        <img className={"img-section1"} src={active ? active.safety_image : "https://www.livecars.ru/l/news/2017/11/03/nissan-gt-r-2018/1.jpg?1509725147"} />
                    </div>
                    <div className={"text"} style={{ backgroundColor: "#62647D" }}>
                        <span>
                            <h2>Безопасность</h2>
                            <p style={{wordBreak:'break-all', wordWrap:'break-word'}}>{active ? active.safety : ""}</p>
                        </span>
                    </div>
                    <div className={"activ-img2"}>
                        <img className={"img-section1"} src={active ? active.safety_image : "https://www.livecars.ru/l/news/2017/11/03/nissan-gt-r-2018/1.jpg?1509725147"} />
                    </div>
                </div>

                {
                    active && active.images && active.images.length > 0?
                <div className={"slider-activCar"}>
                    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <Slyder databaseImg={active.images} />
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                            data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                            data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true" />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                :
                null
            }
                <div className={"config-img-car"}>
                    <img src={imgConfig} alt="" />
                    <div className={"border-text-config"}>
                        <h4 >Модификация</h4>
                        <p>Узнайте, какая модификация этой машины может стать идеальным сочетанием стиля, производительности и экономичности</p>
                        <div className={"booton-config"} onClick={() => { this.setState({ modification: true }) }}>Узнать подробнее</div>
                    </div>
                </div>


                <div className={"peculiarities"} id={"peculiarities"}>
                    <h1>Особенности</h1>
                    <div className={"blok-peculiarities"}>{active !== undefined && active.definitiv && active.definitiv.length>0 ? active.definitiv.map((item, index) => {
                        return (
                            <div key={index} className={"block-li"}>
                                <li><span>{item.text}</span> </li>
                            </div>
                        )
                    }) : ''}
                    </div> 
                </div><a href={active !== undefined ? active.data : null}>
                <div  className="peculiarities pris-list" id="price_list">
                    <h1>Прайс-лист и спецификации</h1>
                        <img src={pdfImg} alt="img" />
                        <span>{active !== undefined ? active.title : null}</span>
                </div>
                 </a>
                <Form />

                <div className={"peculiarities pris-list"} id={"vsemodeli"}>
                    <h1>Все модели</h1>
                    <div className={"border-card-car"}>
                        {database && database.length > 0 ? database.map((item, index) => {
                            return (
                                <Link onClick={()=>{this.reloadPage(`/cars/${item.id}/`)}} key={index} id={index} className={"card-car-model"} to={`/cars/${item.id}/`}>
                                        <h2>{item.title}</h2>
                                        <hr />
                                        <p>$ {item.price}</p>
                                        <div className={"block-card"}>
                                        </div>
                                        <span>{item.year}</span>
                                        <img className={"img-card"} alt="img" src={item.colors[0].car_image} />
                                        <img className={"img-card-hover"} alt="img" src={item.colors[1].car_image} />
                                </Link>
                            )
                        }): 'Загрузка...'}
                    </div>
                    <center>
                        <span className={"bottom-text-activCar"}>
                            Мы готовы оказать вам превосходное обслуживание и профессиональную поддержку на каждом этапе. Посетите наш сайт, чтобы осуществить свою заветную мечту.
                        </span>
                    </center>
                </div>

                <div className={"bottom-activ-syte"}>
                    <img src={logotip} alt="img" />
                </div>

            </>
        )
    }
}


function Slyder(databaseImg) {
    return  databaseImg.databaseImg.map((item, items) => {
        if (items === 0) {
            return (
                <div key={item.id} className="carousel-item active">
                    <img src={item.image} className="d-block w-100" alt="..." />
                </div>
            )
        } else {
            return (
                <div key={item.id} className="carousel-item">
                    <img src={item.image} className="d-block w-100" alt="..." />
                </div>
            )
        }


    }) 
}


const mapStateToProps = (state) => ({
    cars: state.app.cars,
    car: state.app.car,
});

export default withRouter(connect(mapStateToProps)(Activcar));