import "./modifig.css"
import {Component} from "react";
import imgcar from "../../../../assets/Маторики/Размер машины 2d.png"
import wheels from "../../../../assets/Маторики/шина.png"
import block1 from "../../../../assets/Маторики/block.png"
import block2 from "../../../../assets/Маторики/block 2.png"
import infoimg from "../../../../assets/Маторики/eeeeeeeee.jpg"
import { Link } from "react-router-dom";

class Modification extends Component{



    render() {
        const {database,activ}=this.props;
        return(
            <div className={"modification-background-block"} >
                <div className={"modification-block"}>
                    <img src={block1} className={"block-img1"} alt="img"/>
                    <img src={block2} className={"block-img2"} alt="img"/>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                         className="bi bi-x-lg icon-x" viewBox="0 0 16 16" onClick={()=>{this.props.exit()}}>
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                    <br/>
                    <h1>{activ.title}</h1>
                    <br/>
                    <div className={"block-modification-content"}>
                        <div className={"modification-img-block"}>
                            <div className={"img-blog"}>
                                <img src={imgcar} alt="img"/>
                                {/* <div className={"vysota"}>
                                    10005
                                </div>
                                <span className={"dlina"}>522</span> */}
                            </div>
                            <br/>
                            <div className={"text-modification-info"}>
                                <div>
                                    <center>
                                        <span>объём</span>
                                        <h2>{activ.volume}</h2>
                                    </center>
                                </div>
                                <div>
                                    <center>
                                        <span>Мощность</span>
                                        <h2>{activ.power.slice(0,8)}</h2>
                                    </center>
                                </div>
                                <div>
                                    <center>
                                        <span>Коробка передач</span>
                                        <h2>{activ.transmission}</h2>
                                    </center>
                                </div>
                                <div>
                                    <center>
                                        <span>Топливо</span>
                                        <h2>{activ.fuel}</h2>
                                    </center>
                                </div>
                                <div>
                                    <center>
                                        <span>Привод</span>
                                        <h2>{activ.drive_type.split(' ')[0]}</h2>
                                    </center>
                                </div>
                                <div>
                                    <center>
                                        <span>Расход</span>
                                        <h2>{activ.consumption}Л</h2>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"wheels-block"}>
                        <h1>{activ.wheels}</h1>
                        <div>
                            <img src={wheels} alt="img"/>
                        </div>

                    </div>

                    <div className={"info-activCar-modification-block"}>
                    <img src={infoimg}/>
                        <div className={"text-information-car"}>
                            <p> <h4>Общая информация</h4></p>
                            {
                                activ.settings.map(e=>(
                                        <div><span>{e.text}</span> <br /><h5>{e.text2}</h5></div>
                                ))
                            }
                        </div>
                        <div  className={"text-information-car2"}>
                            <p><h4>Двигатель</h4></p>
                            {
                                activ.engine.map(e=>(
                                        <div><span>{e.text}</span> <br /><h5>{e.text2}</h5></div>
                                ))
                            }

                        </div>
                    </div>
                    <div className={"bottom-activ-syte-modification"}>
                        {database.map((item,index)=>{
                            return(
                                <div key={index}><h2 ><a style={{color:"white"}} onClick={()=>this.props.exit()} href={`/cars/${item.id}/`}>{item.title}</a></h2></div>
                            )
                        })}
                    </div>

                </div>
            </div>
        )
    }
}
export default Modification;