import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getNews, getSettings } from '../redux/reducers/app';
import Padval from '../components/Bottom syte/padval';
class AllNews extends Component {
        componentDidMount() {
                this.props.dispatchGetNews();
                this.props.dispatchGetSettings();
        }
        componentDidUpdate(prevProps) {
                if (this.props.dispatchGetSettings !== prevProps.dispatchGetSettings || this.props.settings.length < 0) {
                        this.props.dispatchGetSettings();
                }
                if (this.props.dispatchGetNews !== prevProps.dispatchGetNews || this.props.news.length < 0) {
                        this.props.dispatchGetNews();
                }
        }
        render() {
                const { news, settings } = this.props;
                const Events = news && news.length > 0 ? news.map((item, index) => {
                        return (
                                <div key={index} className={"card"}>
                                        <img src={item.image} alt="img" />
                                        <div>
                                                <h5>{item.title}</h5>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-event" viewBox="0 0 16 16"><path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" /><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" /></svg>
                                                        {item.created}
                                                </span>
                                                <p dangerouslySetInnerHTML={{ __html: item.description }}/>
                                        </div>
                                </div>
                        )
                }) : null;


                return (
                        <>
                                <div className={"border-events all_news"}>
                                        {Events}

                                </div>
                                {
                                        settings && settings.length > 0 ?
                                                <Padval settings={settings} />
                                                : "<h1> Загрузка...</h1>"
                                }
                        </>
                )
        } s
}
const mapStateToProps = (state) => ({
        settings: state.app.settings,
        news: state.app.news,

});
const mapDispatchToProps = (dispatch) => ({
        dispatchGetNews: () => dispatch(getNews()),
        dispatchGetSettings: () => dispatch(getSettings()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllNews);