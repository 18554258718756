import axios from 'axios';
const GET_PARTNERS = "GET_PARTNERS"
const GET_SETTINGS = "GET_SETTINGS"
const GET_MAIN = "GET_MAIN"
const GET_CARS = "GET_CARS"
const GET_CAR = "GET_CAR"
const GET_NEWS = "GET_NEWS"
const GET_NEWS_DETAIL = "GET_NEWS_DETAIL"

const domain = 'https://jetour.kg/api/v1/'
export const textdomain = 'https://jetour.kg/api/v1/'

const initState = {
    settings: [],
    main: [],
    cars: [],
    partners: [],
    news: [],
    news_detail: {},
    car: {},
};

export const app = (state = initState, action) => {
    const createCase = (key) => {
        return {
            ...state,
            [key]: action[key]
        }
    }
    switch (action.type) {
        case GET_SETTINGS: {
            return createCase('settings')
        }
        case GET_MAIN: {
            return createCase('main')
        }
        case GET_CARS: {
            return createCase('cars')
        }
        case GET_CAR: {
            return createCase('car')
        }
        case GET_PARTNERS: {
            return createCase('partners')
        }
        case GET_NEWS: {
            return createCase('news')
        }
        case GET_NEWS_DETAIL: {
            return createCase('news_detail')
        }
        default:
            return state

    }
}
const getApi = (type, key, url) => {
    return (dispatch) => {
        axios(`${domain}${url}`)
            .then(({ data }) => dispatch({ type, [key]: data }))
    }
}
export const getSettings = () => {
    return getApi(GET_SETTINGS, 'settings', '')
}
export const getMain = () => {
    return getApi(GET_MAIN, 'main', 'main/')
}
export const getCars = () => {
    return getApi(GET_CARS, 'cars', 'cars/')
}
export const getPartners = () => {
    return getApi(GET_PARTNERS, 'partners', 'partners/')
}
export const getNews = () => {
    return getApi(GET_NEWS, 'news', 'news/')
}
export const getNewsDetail = (id) => {
    return getApi(GET_NEWS_DETAIL, 'news_detail', `news/${id}/`)
}
export const getCar = (id) => {
    return getApi(GET_CAR, 'car', `cars/${id}/`)
}
