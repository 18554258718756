import React from 'react'
import { Route, Routes } from 'react-router'
import MainPage from '../pages/MainPage'
import Car from '../pages/Car'
import Info from '../pages/Info'
import Contact from '../components/Contacts/contact'
import AllCars from '../pages/AllCars'
import AllNews from '../pages/AllNews'


export default function MainRoutes() {
    const PUBLIC_ROUTES = [
        {link:"", element:<MainPage />, id:1},
        {link:"/cars/:id/", element:<Car />, id:2},
        {link:"/info/", element:<Info/>, id:3},
        {link:"/contacts/", element:<Contact/>, id:4},
        {link:"/cars/", element:<AllCars/>, id:5},
        {link:"/news/", element:<AllNews/>, id:6},
        {link:"/sitemap.xml/", element:null, id:7},

    ];
    return (
        <>
            <Routes>
                {
                    PUBLIC_ROUTES.map((item)=>(
                        <Route path={item.link} element={item.element} key={item.id}/>
                    ))
                }
            </Routes>
        </>
    )
}
