import React, { Component } from "react";
import "./Main.css"

import imgLogoCenter from "../../assets/Маторики/JETOUR.png"
import { connect } from 'react-redux';
import { getMain } from '../../redux/reducers/app';
import { Link } from "react-router-dom";
import {hover} from "@testing-library/user-event/dist/hover";
class Main extends Component {
    componentDidMount() {
        this.props.dispatchGetMain();
    }
    componentDidUpdate(prevProps) {
        if (this.props.dispatchGetMain !== prevProps.dispatchGetMain || this.props.main.length < 0) {
            this.props.dispatchGetMain();
        }
    }

    render() {
        const { main } = this.props;
        return (

            <div className={"main-border"}>
                <div className={"main-img-logo-center"}>
                    <img src={imgLogoCenter} alt="img" />
                    <center> <span>В КЫРГЫЗСТАНЕ</span></center>
                </div>
                <div className={"main-info-text-bottom"}>
                    <div className={"text-main"}>
                        <p>Элегантность</p>
                        <p>Инновация</p>
                        <p>Исключительность</p>
                    </div>
                    <Link className={"Learn-more-button"} to='/info/'>
                        Подробнее 	&#8594;
                    </Link>
                </div>
                <div className={"main-video-background"}>
                    {
                        main && main.length > 0? <video autoPlay muted loop src={main[0].main_video} /> : ''
                    }

                </div>

            </div>
        )
    }


}

const mapStateToProps = (state) => ({
    main: state.app.main
});
const mapDispatchToProps = (dispatch) => ({
    dispatchGetMain: () => dispatch(getMain())
});
export default connect(mapStateToProps, mapDispatchToProps)(Main);