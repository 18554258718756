
import "./form.css"
import React, { Component } from "react";
import axios from "axios";
import { textdomain } from "../../../redux/reducers/app";
class FormApplications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone_number: "",
            text: "Хочу записаться на тест-драйв", 
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleSubmit = () => {
        const data = new FormData();
        const { name, email, phone_number, text } = this.state;
        const csrfToken = this.getCookie('csrftoken');
        const headers = {
            'X-CSRFToken': csrfToken,
        };
        data.append('name', name);
        data.append('email', email);
        data.append('phone_number', phone_number);
        data.append('text', text);
        axios.post(`${textdomain}email/`, data,{headers })
            .then(response => {
                alert("Ваша заявка принята! Ожидайте ответа");
                this.setState({
                    formData: {
                        name: "",
                        email: "",
                        phone: "",
                        inquiry: "Хочу записаться на тест-драйв",
                    },
                });
                
                this.props.form("")
            })
            .catch(error => {
                console.error("Ошибка при отправке:", error);
            });
    };
    getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };
    render() {
        const { name, email, phone_number, text } = this.state;
        return (
            <>
                <div className={"borger-Applications"}>
                    <div className={"block-Applications"}>
                        <svg onClick={() => { this.props.form("") }} xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            className="bi bi-x-square" viewBox="0 0 16 16">
                            <path
                                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                        <center>
                            <h1>Форма заявки</h1>
                        </center>
                        <div className="block-inp2">
                            <div className="block-inp-text">
                                <div className="form-floating" style={{margin:'10px 0 !important'}}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInputGrid"
                                        placeholder="Имя"
                                        name="name"
                                        value={name}
                                        onChange={this.handleInputChange}
                                    />
                                    <label htmlFor="floatingInputGrid">Ваше имя</label>
                                </div>
                                <div className="form-floating">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="floatingInputGrid1"
                                        placeholder="name@example.com"
                                        name="email"
                                        value={email}
                                        onChange={this.handleInputChange}
                                    />
                                    <label htmlFor="floatingInputGrid1">Email адрес</label>
                                </div>
                                <div className="form-floating">
                                    <input
                                        type="tel"
                                        className="form-control"
                                        id="floatingInputGrid2"
                                        placeholder="+996 554 54 77 66"
                                        name="phone_number"
                                        value={phone_number}
                                        onChange={this.handleInputChange}
                                    />
                                    <label htmlFor="floatingInputGrid2">Телефон номер</label>
                                </div>
                            </div>
                            <div className="form-floating">
                                <select
                                    className="form-select"
                                    id="floatingSelectGrid"
                                    name="text"
                                    value={text}
                                    onChange={this.handleInputChange}
                                >
                                    <option value="Хочу записаться на тест-драйв">Хочу записаться на тест-драйв</option>
                                    <option value="Хочу получить подробную консультацию">Хочу получить подробную консультацию</option>
                                    <option value="Хочу поговорить об оформлении">Хочу поговорить об оформлении</option>
                                    <option value="Обратная связь!">Обратная связь!</option>
                                </select>
                                <label htmlFor="floatingSelectGrid">Часто задаваемые вопросы</label>
                            </div>
                            <button
                                style={{ marginRight: "1em" }}
                                className={"btn btn-primary"}
                            >
                                <i className="bi bi-telegram" /> Telegram bot
                            </button>
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={()=>{this.handleSubmit()}} >
                                Отправить
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }


}
export default FormApplications;