import React, {Component} from "react";

import "./Assembly.css"
import Models from "./CarModels/CarModels"

class Assembly extends Component{

    render() {
        const { activCar} = this.props;
        return(
            <>
                <div className={"body-border"} >
                    <Models activCar={(w,activDatabase)=>activCar(w,activDatabase,"activCar")}/>
                </div>
            </>
        )
    }
    }
export default Assembly;