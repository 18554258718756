import React from 'react'
import Activcar from "../components/Body/Activ/Active"


export default function Car() {
  return (
      <>
        <Activcar/>
      </>
  )
}
