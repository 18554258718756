import React from 'react'
import Main from "../components/Main/Main";
import Assembly from "../components/Body/Assembly";
import Guarantee from "../components/Body/Guarantee/Guarantee";

export default function MainPage() {
  return (
    <div>
        <Main/>
        <Assembly/>
        <Guarantee/>
    </div>
  )
}
