import "./jetourInfo.css"
import video from "../../assets/VIDEO/34831524.mp4"
import React from "react";

import imgT2 from "../../assets/Main img car/image 24.png"
import imgServis from "../../assets/Main img car/Cehdbc.png"
import imgglobus from "../../assets/Main img car/Карта.png"

function JetourInfo() {
    return (
        <>
            <div className={"block-JetourInfo"}>
                <video autoPlay muted loop src={video} />
                <div className={"backgroundColor"} />
                <div>
                    <p>
                        наша команда готовы ответить на все вопросы
                        <span>
                            Добро пожаловать в мир люксовых машин, где ваша удовлетворенность - наша главная цель!
                        </span>
                        &mdash; JETOUR &mdash;
                    </p>
                </div>

            </div>
            <div className={"info-jet-blok"}>
                <div className={"cont"} >
                    <h1 className={"text-models-body"}>ОБЗОР БРЕНДА JETOUR</h1>

                    <div className="texts_nj3gd">
                        <p>
                            Jetour - это суббренд китайского автопроизводителя Chery, который был запущен в 2018 году. Бренд Jetour позиционируется как производитель экономичных и доступных кроссоверов в средней ценовой категории. Они предлагают широкий выбор моделей, которые сочетают в себе функциональность, комфорт и современный дизайн.
                        </p>
                        <p>Автомобиль JETOUR - это совершенно новый автомобильный бренд,
                            разработанный в ответ на тенденции развития рынка и изменения потребительского спроса.</p>
                        <p>JETOUR стремится стать лидером на рынке
                            'Путешествие плюс' и предоставлять более разумные туристические решения для большего числа
                            семей и индивидуальных клиентов.</p>
                    </div>
                </div>
                <img src={imgT2} alt={"Jetour T2"} />
            </div>
            <div className={"JETOUR-SERVICE"}>
                <h1 className={"text-models-body"}>СЕРВИС JETOUR</h1>
                <div className={"cont"}>
                    <img src={imgServis} alt={'Сервисы Jetour'} />
                    <div className={"servis-cont"}>
                        <h1>СЕРВИС И ОБСЛУЖИВАНИЕ</h1>
                        <br />
                        <h4>ПРОЙДИТЕ РЕГЛАМЕНТНОЕ ТЕХНИЧЕСКОЕ ОБСЛУЖИВАНИЕ</h4>
                        <br />
                        <p>Автоцентр Jetour в Молдове располагает высокотехнологичным сервисным центром, где работы по обслуживанию и ремонту ведутся в соответствии с требованиями компании Jetour с применением оригинального диагностического оборудования, специальных инструментов и оснастки для обслуживания и ремонта автомобилей Jetour.
                            Мы обладаем полным комплектом специального оборудования и инструментов, гарантирующих высокое качество и минимальные затраты времени на выполнение работ. Фирменный сервис гарантирует: Каждая работа на фирменном сервисе выполняется в строгом соответствии с заказом клиента Заявка выполняется в согласованные с клиентом сроки. В случае несвоевременного выполнения заказа, вас должны оповестить об этом заранее, сообщить причину и согласовать новый срок. Клиент вправе потребовать объяснения каждой позиции выставленного счета.</p>
                    </div>

                </div>
            </div>
            <div className={"block-carta-url"}>
                <img src={imgglobus} alt={"Цифравая карта(адрес авто садона Jetour Ош Бишкек)"} />
                <hr />
                <a href={"https://go.2gis.com/hlgun"} target="_blank">
                    <div>
                        <h2>
                            Наш адрес в Оше
                        </h2>
                        <p>
                            г. Ош, ул. ​Аскар Шакиров, 108а
                        </p>
                    </div>
                </a>
                <hr className={"hr"} />
                <a target="_blank" href={'https://www.google.com/maps?q=Jetour,+2/3+%D1%83%D0%BB%D0%B8%D1%86%D0%B0+%D0%A8%D0%B0%D0%B1%D0%B4%D0%B0%D0%BD+%D0%B1%D0%B0%D0%B0%D1%82%D1%8B%D1%80%D0%B0,+%D0%91%D0%B8%D1%88%D0%BA%D0%B5%D0%BA+720011&ftid=0x389eb705a00cb11b:0x84ced99593653bae&hl=ru-KG&gl=kg&coh=199384&entry=gps&lucs=,47087022,47071704,47069508&g_ep=CAISDDYuOTMuMy4yNTkyMBgAIJ6dCiobLDQ3MDg3MDIyLDQ3MDcxNzA0LDQ3MDY5NTA4QgJLRw%3D%3D&g_st=ic'}>
                    <div>
                        <h2>
                            Наш адрес в Бишкеке
                        </h2>
                        <p>
                            г. Бишкек, ул. Шабдан Баатыра 2/20
                        </p>
                    </div>
                </a>
                <hr />

            </div>
        </>
    )
}
export default JetourInfo;