import React, { useState } from "react";
import axios from "axios";
import "./form.css"
import img from "../../../../assets/Маторики/jetour form img.png"
import { textdomain } from "../../../../redux/reducers/app";
function Form() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone_number: "",
        text: "Хочу записаться на тест-драйв",
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
        const { name, email, phone_number, text } = formData;
        // Отправка данных с помощью Axios
        const csrftoken = getCookie('csrftoken');
        const headers = {
            'X-CSRFToken': csrftoken,
        };
        axios.post(`${textdomain}email/`, { name, email, phone_number, text },{headers })
            .then(response => {
                console.log("Успешно отправлено:", response.data);
                // Дополнительная логика после успешной отправки
                setIsSubmitted(true);
                setFormData({
                    name: "",
                    email: "",
                    phone_number: "",
                    text: "Хочу записаться на тест-драйв",
                });
            })
            .catch(error => {
                console.error("Ошибка при отправке:", error);
            });
    };
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      };
      
    return(
        <>
            <div className={"peculiarities"} id={"peculiaritiesForm"}>
                <div className={"block-form"}>
                    <div className="block-inp">
                        {isSubmitted ? (
                            <h3>Заявка принята, спасибо!</h3>
                            ) : (
                                <>
                                <h2>Записаться на тест-драйв</h2>
                                <div className={"block-inp-text"}>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInputGrid"
                                            placeholder="Имя"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="floatingInputGrid">Ваше имя</label>
                                    </div>
                                    <div className="form-floating">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="floatingInputGrid1"
                                            placeholder="name@example.com"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="floatingInputGrid1">Email адрес</label>
                                    </div>
                                    <div className="form-floating">
                                        <input
                                            type="tel"
                                            className="form-control"
                                            id="floatingInputGrid2"
                                            placeholder="+996 554 54 77 66"
                                            name="phone_number"
                                            value={formData.phone_number}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="floatingInputGrid2">Телефон номер</label>
                                    </div>
                                </div>
                                <div className="form-floating">
                                    <select
                                        className="form-select"
                                        id="floatingSelectGrid"
                                        name="text"
                                        value={formData.text}
                                        onChange={handleInputChange}>

                                        <option value="Хочу записаться на тест-драйв">Хочу записаться на тест-драйв</option>
                                        <option value="Хочу получить подробную консультацию">Хочу получить подробную консультацию</option>
                                        <option value="Хочу поговорить об оформлении">Хочу поговорить об оформлении</option>
                                        <option value="Обратная связь!">Обратная связь!</option>
                                    </select>
                                    <label htmlFor="floatingSelectGrid">Часто задаваемые вопросы</label>
                                </div>
                                <button className="btn btn-primary" onClick={handleSubmit}>
                                    Отправить
                                </button>
                            </>
                        )}
                    </div>
                    <div className="block-img">
                        <img src={img} alt="Форма" />
                    </div>
                </div>

            </div>
        </>
    )
}
export default Form;