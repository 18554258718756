import React, { Component } from 'react'
import { getCars } from '../redux/reducers/app'
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class AllCars extends Component {
    state = {
        database: null,
    };

    componentDidMount() {
        this.loadData();
    }
    componentDidUpdate(prevProps) {
        if (this.props.cars.length === 0 && prevProps.cars.length !== this.props.cars.length) {
            this.loadData();
        }
        if (prevProps.cars.length !== this.props.cars.length) {
            this.setState({
                database: this.props.cars,
            });
        }
    }
    loadData() {
        const { dispatch } = this.props;
        dispatch(getCars());
    }
    render() {
        const { database } = this.state;

        return (
            <div className={"peculiarities pris-list"} id={"vsemodeli"}>
                <br />
                <br />
                <h1>Все модели</h1>
                <div className={"border-card-car"}>
                    {database && database.length > 0 ? database.map((item, index) => {
                        return (
                            <Link key={index} id={index} className={"card-car-model"} to={`/cars/${item.id}/`}>
                                <h2>{item.title}</h2>
                                <hr />
                                <p>$ {item.price}</p>
                                <div className={"block-card"}>
                                </div>
                                <span>{item.year}</span>
                                <img className={"img-card"} alt="img" src={item.images[0].image} />
                                <img className={"img-card-hover"} alt="img" src={item.colors[0].car_image} />
                            </Link>
                        )
                    }) : 'Загрузка...'}
                </div>
                <center>
                    <span className={"bottom-text-activCar"}>
                        Мы готовы оказать вам превосходное обслуживание и профессиональную поддержку на каждом этапе. Посетите наш сайт, чтобы осуществить свою заветную мечту.
                    </span>
                </center>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cars: state.app.cars,
});
export default connect(mapStateToProps)(AllCars);