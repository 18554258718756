import './Head.css';
import {Link} from 'react-router-dom';
import imgLogo from "../../assets/Маторики/vehica-logo-black-retina-1.png"
import imgLogo1 from "../../assets/Маторики/vehica-logo-dark-retina-2.png"

import {Component} from "react";

class Head extends Component {
    state = {
        aa: false,
        productId: null,
        is_contact: false,
        currentUrl: window.location.pathname

    };
    componentDidMount(prevProps) {
        const pathname = window.location.pathname;
        const productId = this.extractProductId(pathname);
        if (productId !== null) {
            this.setState({ productId });
        }
    }
    extractProductId = (pathname) => {
        const parts = pathname.split('/');
        const idIndex = parts.indexOf('cars') + 1;
        if (idIndex !== 0 && idIndex < parts.length) {
            return parts[idIndex];
        }
        return null;
    };

    al = () => {
        let too = window.pageYOffset - 100;
        let top = window.pageYOffset > 1000 && too < window.pageYOffset;

        if (top) {
            this.setState({ aa: true })
        } else {
            this.setState({ aa: false })
        }
    };

    indexof=(text,textOf)=>{
        let t =text.lastIndexOf(textOf);
        return t >0
    };

    render() {
        const urlCite = window.location.href;

        const { productId, is_contact } = this.state;
        window.onscroll = () => {
            this.al()
        };

        const urlCars = this.indexof(urlCite,"/cars/");

        const { activ, onActiv } = this.props;
        return (
            <>
                <div className={!this.state.aa && is_contact === false ? "head-border" : is_contact === true ? "head-border is_contact" :
                    null} id={"heder_syte1"} style={activ === "contact" ? { backgroundColor: "#222732" } : null} >
                    <nav className="navbar">
                        <div className="container-fluid">
                            <button style={{ color: "white" }} className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"
                                aria-label="Toggle navigation">
                                <svg style={{ color: "white" }} xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                </svg>
                            </button>
                            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                                        Jetour
                                    </h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                                </div>
                                <div className="offcanvas-body">
                                    <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                        <li className="nav-item">
                                            <a className="nav-link active" aria-current="page" href={'/'}>
                                                Главный
                                            </a>
                                        </li>

                                        {urlCars?null:
                                            <li className="nav-item">
                                                <a href={'/cars/1/'} className="nav-link">
                                                    ОБЗОР
                                                </a>
                                            </li>
                                        }
                                        <li className="nav-item">
                                            <Link to={'/contacts/'} onClick={() => this.setState({ is_contact: true })} className="nav-link">
                                                Контакты
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <a href={'/news/'} className="nav-link">
                                                Новости
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href={'/cars/'} className="nav-link">
                                                Модели
                                            </a>
                                        </li>

                                        {urlCars?
                                        <>
                                            <li className="nav-item">
                                                <a href={'#peculiarities'} className="nav-link">
                                                    ОСОБЕННОСТИ
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href={'#price_list'} className="nav-link">
                                                    ПРАЙС-ЛИСТ
                                                </a>
                                            </li>
                                        </>
                                        :null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>



                    <a href={'/'} className={"header-img-logo"}>
                        <img className={"header-img-logo"} src={imgLogo1} alt="img" />
                    </a>
                    <div className={"header-sections-menu"}>
                        <a href={'/cars/'}>
                            <div style={{ color: "white" }}>
                                МОДЕЛИ
                            </div>
                        </a>
                        {urlCars ?
                                <>
                                    <a href={"#peculiarities"}>
                                        <div style={{ color: "white" }}>
                                            ОСОБЕННОСТИ
                                        </div>
                                    </a>
                                    <a href={"#price_list"}>
                                        <div style={{ color: "white" }}>
                                            ПРАЙС-ЛИСТ
                                        </div>
                                    </a>
                                </>
                            :
                                <>
                                    <a href={"/cars/1/"}>
                                        <div style={{ color: "white" }}>
                                            ОБЗОР
                                        </div>
                                    </a>
                                    <Link to={'/news/'}>
                                        <div style={{ color: "white" }}>
                                            НОВОСТИ
                                        </div>
                                    </Link>
                                </>
                        }

                        <Link style={{ cursor: "pointer" }} to='/contacts/'>
                            <div style={{ color: "white" }} >
                                КОНТАКТЫ
                            </div>
                        </Link>
                    </div>

                    <button className={"header-feedback button_form"}>
                        {activ === "main" ?
                            <span onClick={() => { this.props.form("applications") }}>
                                Обратная связь
                            </span>
                            :
                            <a href={'/'}>
                                <i className="bi bi-box-arrow-right exit-botton" style={{ color: "#ffffff" }} onClick={() => { onActiv("main") }} />
                            </a>
                        }
                    </button>
                </div>

                <div className={this.state.aa ? "head-border2" : "dispay-none"} id={"heder_syte"}>
                    <nav className="navbar">
                        <div className="container-fluid">
                            <button style={{ color: "white" }} className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"
                                aria-label="Toggle navigation">
                                <svg style={{ color: "black" }} xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                </svg>
                            </button>
                        </div>
                    </nav>
                    <a href={""} className={"header-img-logo"}>
                        <img className={"header-img-logo"} src={imgLogo} />
                    </a>
                    <div className={"header-sections-menu"}>
                        <a href={"/cars/"}>
                            <div style={{ color: "black" }}>
                                МОДЕЛИ
                            </div>
                        </a>
                        {!urlCars?
                            <>
                                <a href={"/cars/1"}>
                                    <div style={{ color: "black" }} onClick={() => { this.props.ObzorCar() }}>
                                        ОБЗОР
                                    </div>
                                </a>
                                <a href={"/news/"}>
                                    <div style={{ color: "black" }}>
                                        НОВОСТИ
                                    </div>
                                </a>
                            </>
                            :
                                <>
                                    <a href={"#peculiarities"}>
                                        <div style={{ color: "black" }}>
                                            ОСОБЕННОСТИ
                                        </div>
                                    </a>
                                    <a href={"#price_list"}><div style={{ color: "black" }}>
                                        ПРАЙС-ЛИСТ
                                    </div>
                                    </a>
                                </>
                                }
                        <Link style={{ cursor: "pointer" }} to={'/contacts/'} onClick={() => this.setState({ is_contact: true })}>
                            <div style={{ color: "#000000" }} >
                                КОНТАКТЫ
                            </div>
                        </Link>
                    </div>
                    <div className={"header-feedback"}>
                        {activ === "main" ?
                            <span onClick={() => { this.props.form("applications") }}>
                                Обратная связь
                            </span>
                            :
                            <a href={''}>
                                <i className="bi bi-box-arrow-right exit-botton" style={{ color: "#000000" }} onClick={() => { onActiv("main") }} />
                            </a>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default Head;
