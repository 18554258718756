import React, { Component } from "react";
import "./Models.css"

import logo from "../../../assets/Маторики/Logo.png"
import noCar from "../../../assets/Маторики/No car.png"
import Characteristic from "./Characteristic/Characteristic";
import { connect } from 'react-redux';
import { getCars } from "../../../redux/reducers/app";

class Models extends Component {
    state = {
        textcenterCard: 'Выберите машыну!',
        activDatabase: null,
        colorCars: 0,
    };
    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.cars && prevProps.cars.length === 0) {
            this.loadData();
        }
        if (prevProps.cars.length !== this.props.cars.length) {
            this.setState({
                textcenterCard: this.props.cars[0].title,
                activDatabase: this.props.cars[0],
            });
        }
    }
    handleClick = (id) => {
        this.setState(prevState => ({
            textcenterCard: this.props.cars[id].title,
            activDatabase: this.props.cars[id],
        }));
    };
    loadData() {
        const { dispatch } = this.props;
        dispatch(getCars());
    }
    render() {
        const { textcenterCard, activDatabase, colorCars } = this.state;
        const { cars } = this.props;
        return (
            <>
                <h1 className={"text-models-body"}>МОДЕЛИ</h1>
                <div className={"body-models-border"}>
                    <div className={"name-car-blok"} id={"main_models"}>
                        <div className={"model-img-logo"}>
                            <span className={"name-car"}> <img alt={"logo"} src={logo} />
                                {textcenterCard}
                            </span>
                        </div>
                        <div className={"models-text-backraund"}>
                            <p>Уверенность</p>
                            <p>в каждом</p>
                            <p>пути</p>
                        </div>
                    </div>
                    {
                        activDatabase!== null && activDatabase.id?
                            <a href={`/cars/${activDatabase.id}/`}>
                        <img className={"main-models-img-car"} alt={"car"} src={activDatabase && activDatabase.colors && activDatabase.colors[colorCars] ? activDatabase.colors[colorCars].car_image : noCar} />
                            </a>
                            :
                    'Загрузка...'
                    }
                    
                    <div className={"slider-bok"}>
                        {

                            cars.length > 0 ? (

                                cars.map((item,index) => {
                                    return (
                                        <CardSlaiders {...item} key={item.id} index={index} onClick={this.handleClick} />
                                    )
                                })
                            ) : 'Зашрузка'
                        }
                    </div>
                    <div className={"border-colorCar"}>
                        {
                            cars.length > 0 && activDatabase && activDatabase.colors && activDatabase.colors.length > 0 ? activDatabase.colors.map((color, index) => (
                                <div id={color.id} key={color.id} style={{ backgroundColor: color.color }} onClick={() => { this.setState({ colorCars: index }) }} />
                            )) : null
                        }
                        {
                            !activDatabase || !activDatabase.colors[colorCars] ?
                                <span className={"warning"}>Выберите другой цвет!</span>
                                : null
                        }
                    </div>
                </div>
                <Characteristic infoDataBase={activDatabase} />
            </>
        )
    }
}

class CardSlaiders extends Component {
    state = {
        cardslaider: true,
    };
    componentDidUpdate(prevProps) {
        const { title, index } = this.props;
        this.state.cardslaider = title !== this.props.Active;
        this.index = index
    }

    render() {
        const { colors, id, name , title} = this.props;

        const { cardslaider } = this.state;
        return (
            <div onClick={()=>this.props.onClick(this.index)} key={id} id={"carModel"} className={"models-slider-img-border"}>
                <img src={colors[0].car_image} className={cardslaider ? "" : " bottom-border-animation"} id="image" alt={"Jetour "+title} />
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    cars: state.app.cars
});
export default connect(mapStateToProps)(Models);