import React, {Component, useEffect} from "react";
import Head from "./components/Head/Head";
import MainRoutes from './Routes/MainRoutes';
import FormApplications from "./components/Head/Form/form";
import {Database} from './consts/db'
import { useLocation } from "react-router-dom";
function ScrollToTopOnLink({ children }) {
    const location = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);
  
    return children;
  }

class App extends Component{
    state = {
        activ:'main',
        activDatabase:null,
        form: "",
    };
    
    render() {
        const form = this.state.form ===  "applications"? <FormApplications form={(text)=>{this.setState({form:text})} } />:null;
        return (
            <ScrollToTopOnLink>

                {form}
                <Head  activ={this.state.activ} onclickMain={(text)=>{this.setState({activ:text})}} onclockCars={(item, text)=>{this.setState({activDatabase:item,activ:text})}} form={(text)=>{this.setState({form:text})}}  ObzorCar={()=>{this.setState({activ:"activCar", activDatabase: Database[0].car })}} onActiv={(activ)=>{this.setState({activ})}}/>
                <MainRoutes/>

            </ScrollToTopOnLink>
        );
    }
}
export default App;
