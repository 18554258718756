import { Component } from "react";
import InfiniteSlider from "./Crol/Crol"
import "./Guarantee.css"
import Padval from "../../Bottom syte/padval"
import Events from "./Events/Events"
import { connect } from 'react-redux';
import { getNews, getPartners, getSettings } from "../../../redux/reducers/app";
import { Link } from "react-router-dom";

class Guarantee extends Component {
    componentDidMount() {
        this.props.dispatchGetSettings();
        this.props.dispatchGetPartners();
        this.props.dispatchGetNews();
    }
    componentDidUpdate(prevProps) {
        if (this.props.dispatchGetSettings !== prevProps.dispatchGetSettings || this.props.settings.length < 0) {
            this.props.dispatchGetSettings();
        }
        if (this.props.dispatchGetPartners !== prevProps.dispatchGetPartners || this.props.partners.length < 0) {
            this.props.dispatchGetPartners();
        }
        if (this.props.dispatchGetNews !== prevProps.dispatchGetNews || this.props.news.length < 0) {
            this.props.dispatchGetNews();
        }
    }
    render() {
        const { settings, partners, news } = this.props;
        return (
            <>

                <div className="container-about-the-brand">
                    <div className={"boxintex-shadow"} />
                    <h1 className={"text-jetour"}>О бренде</h1>
                    <br />
                    {
                        settings && settings.length>0 && settings[0].about_us ?  <div className="about_us" style={{color:'#ffffff !important'}} dangerouslySetInnerHTML={{ __html: settings[0].about_us }} />: 'Загрузка...'
                    }
                    <Link to={'/info/'} className={"booton-info-jetour text-jetour"}>Узнать подробнее  &#8594;</Link>
                </div>
                <br />
                <h1 className={"text-models-body"} style={{ marginLeft: " 5%" }} id={"news"}>
                    О событиях
                </h1>
                {
                    news && news.length > 0 ?
                        <Events database={news} />: 'Загрузка'
                }
                
                <br />
                <br />
                <h1 className={"text-models-body"} style={{ marginLeft: " 5%" }} id={"news"}>Нам доверяют</h1>
                <div className="blank">
                {
                    partners && partners.length > 0 ? <InfiniteSlider images={partners} />: 'Загрузка'
                }
                </div>
                {
                        settings && settings.length>0 ? 
                            <Padval settings = {settings} />
                        : 'Загрузка...'
                    }
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    settings: state.app.settings,
    partners: state.app.partners,
    news: state.app.news,

});
const mapDispatchToProps = (dispatch) => ({
    dispatchGetSettings: () => dispatch(getSettings()),
    dispatchGetNews: () => dispatch(getNews()),
    dispatchGetPartners: () => dispatch(getPartners())
});
export default connect(mapStateToProps, mapDispatchToProps)(Guarantee);